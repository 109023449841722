<template>
  <div class="card card-flush py-3">
    <div class="d-flex justify-content-center py-3">
      <h1 class="card-title fs-2x">Car Specifications</h1>
    </div>
    <h3 class="text-center fw-normal m-0">
      Provide Information about car you want to add
    </h3>
    <div class="card-body px-xl-20">
      <el-form :model="formData" @submit.prevent="null">
        <div class="row gx-xl-24 py-lg-3">
          <div class="col-xl-4 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="brand">Brand</label>
            <div class="d-flex">
              <el-select
                id="brand"
                placeholder="Select Brand"
                v-model="formData.brand"
              >
                <el-option
                  :label="brand.name"
                  :key="i"
                  v-for="(brand, i) in brands"
                  :value="brand.id"
                >
                </el-option>
              </el-select>
              <button
                data-bs-toggle="modal"
                data-bs-target="#brand-modal"
                class="btn btn-primary ms-5 px-4"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-outlined fs-1x"> edit </span>
                </div>
              </button>
            </div>
          </div>
          <div class="col-xl-4 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="model">Model</label>
            <div class="d-flex">
              <el-select
                id="model"
                placeholder="Select Model"
                v-model="formData.model"
                :disabled="!formData.brand"
              >
                <el-option
                  :key="i"
                  v-for="(model, i) in models"
                  :value="model.id"
                  :label="model.name"
                >
                </el-option>
              </el-select>
              <button
                data-bs-toggle="modal"
                data-bs-target="#model-modal"
                class="btn btn-primary ms-5 px-4"
                :disabled="!formData.brand"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-outlined fs-1x"> edit </span>
                </div>
              </button>
            </div>
          </div>
          <div class="col-xl-4 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="subModel">Series</label>
            <div class="d-flex">
              <el-select
                id="subModel"
                placeholder="Select Series"
                v-model="formData.subModel"
                :disabled="!formData.model"
              >
                <el-option
                  :key="i"
                  v-for="(subModel, i) in subModels"
                  :value="subModel.id"
                  :label="subModel.name"
                >
                </el-option>
              </el-select>
              <button
                data-bs-toggle="modal"
                data-bs-target="#sub-model-modal"
                class="btn btn-primary ms-5 px-4"
                :disabled="!formData.model"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-outlined fs-1x"> edit </span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="row gx-xl-24 py-lg-3">
          <div class="col-xl-4 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="type">Type</label>
            <div class="d-flex">
              <el-select
                placeholder="Select Type"
                id="type"
                filterable
                v-model="formData.type"
                :disabled="true"
              >
                <el-option
                  :key="i"
                  v-for="(type, i) in types"
                  :value="type.id"
                  :label="type.name"
                >
                </el-option>
              </el-select>
              <button
                data-bs-toggle="modal"
                data-bs-target="#type-modal"
                class="btn btn-primary ms-5 px-4"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-outlined fs-1x"> edit </span>
                </div>
              </button>
            </div>
          </div>
          <div class="col-xl-8 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="tags">Tags</label>
            <div class="d-flex">
              <div class="dropdown w-100">
                <el-select
                  id="tags"
                  v-model="formData.tags"
                  placeholder="Select Tags"
                  multiple
                  filterable
                >
                  <el-option
                    :key="i"
                    v-for="(tag, i) in tags"
                    :value="tag.id"
                    :label="tag.name"
                  >
                  </el-option>
                </el-select>
              </div>

              <button
                data-bs-toggle="modal"
                data-bs-target="#tag-modal"
                class="btn btn-primary ms-5 px-4"
              >
                <div class="d-flex align-items-center">
                  <span class="material-icons-outlined fs-1x"> edit </span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="row gx-xl-24 py-lg-3">
          <div class="col-xl-4 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="year">Model Year</label>
            <el-input
              id="year"
              type="number"
              v-model="formData.year"
              @change="limitMaxNumber('year', new Date().getFullYear())"
            />
          </div>
          <div class="col-xl-4 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="seats">Seats</label>
            <el-input
              id="seats"
              type="number"
              v-model="formData.seat"
              @change="limitMaxNumber('seat', 10)"
            />
          </div>
          <div class="col-xl-4 pb-3 pb-lg-0">
            <label class="fs-3 fw-bold pb-2" for="year">Doors</label>
            <el-input
              id="doors"
              type="number"
              v-model="formData.door"
              @change="limitMaxNumber('door', 10)"
            />
          </div>
        </div>
        <div class="row gx-xl-24 py-lg-3">
          <div class="col-xl-4">
            <label class="fs-3 fw-bold pb-2" for="transmission"
              >Transmission</label
            >
            <div class="d-flex">
              <el-select id="transmission" v-model="formData.transmission">
                <el-option value="Automatic">Automatic</el-option>
                <el-option value="Manual">Manual</el-option>
              </el-select>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
  <div class="d-flex justify-content-end pt-7">
    <button
      name="submit car specification"
      type="submit"
      class="btn btn-primary fs-3 fw-bolder px-10"
      :disabled="Object.values(formData).some((item) => item === '') || loading"
      data-bs-toggle="modal"
      data-bs-target="#confirm"
    >
      Add to car lists
    </button>
  </div>

  <ConfirmModal ref="modalRef">
    <button
      @click="submit()"
      type="button"
      name="confirm"
      class="btn btn-primary px-10"
    >
      Confirm
    </button>
  </ConfirmModal>

  <AddTypeModal v-model="types"></AddTypeModal>
  <AddTagModal v-model="tags"></AddTagModal>
  <AddBrandModal v-model="brands"></AddBrandModal>
  <AddModelModal
    :models="models"
    :brands="brands"
    :types="types"
    :selectedBrand="formData.brand"
  ></AddModelModal>
  <AddSubModelModal
    :subModels="subModels"
    :models="models"
    :selectedModel="formData.model"
  ></AddSubModelModal>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import ApiService from '@/core/services/ApiService';
import { Actions } from '@/store/enums/StoreEnums';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { hideModal } from '@/core/helpers/dom';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import AddTypeModal from '@/components/modals/AddTypeModal.vue';
import AddTagModal from '@/components/modals/AddTagModal.vue';
import AddBrandModal from '@/components/modals/AddBrandModal.vue';
import AddModelModal from '@/components/modals/AddModelModal.vue';
import AddSubModelModal from '@/components/modals/AddSubModelModal.vue';

export default {
  name: 'Add New Car',
  components: {
    ConfirmModal,
    AddTypeModal,
    AddTagModal,
    AddBrandModal,
    AddModelModal,
    AddSubModelModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const brands = ref([]);
    const models = ref([]);
    const subModels = ref([]);
    const types = ref([]);
    const tags = ref([]);
    const formData = ref({
      brand: '',
      model: '',
      subModel: '',
      type: '',
      tags: [],
      year: '1999',
      seat: '4',
      door: '4',
      transmission: 'Automatic',
    });
    const loading = ref(false);
    const modalRef = ref(null);

    onMounted(async () => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

      const brand = await ApiService.get('car-brand?pageSize=99999');

      const { data: typeData } = await ApiService.get(
        'car-type?pageSize=99999'
      );
      const { data: tagData } = await ApiService.get('car-tag?pageSize=99999');

      brands.value = brand.data.datas;
      models.value = [];
      subModels.value = [];
      types.value = typeData.datas;
      tags.value = tagData.datas;

      setCurrentPageBreadcrumbs('Car & Function', [
        { name: 'Car Lists', path: '/car-function/car-lists' },
        'Add New Car',
        {},
      ]);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    });

    const submit = async () => {
      loading.value = true;
      const data = {
        name: formData.value.year,
        seat: formData.value.seat,
        door: formData.value.door,
        transmission: formData.value.transmission,
        serie: formData.value.subModel,
        tags: formData.value.tags,
      };

      await ApiService.post('car-year', data);

      loading.value = false;
      hideModal(modalRef.value.$el);

      await Swal.fire({
        text: 'Car has been successfully created.',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Go to car lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      router.push({ name: 'Car Lists' });
    };

    const limitMaxNumber = (val, num) => {
      if (formData.value[val] > num) {
        formData.value[val] = num;
      }
    };

    watch(
      () => formData.value.brand,
      async (newBrand) => {
        const { data } = await ApiService.get(
          `car-brand-model?brand=${newBrand}`
        );
        models.value = data.datas;
        formData.value.model = null;
        formData.value.subModel = null;
        formData.value.type = null;
      }
    );

    watch(
      () => formData.value.model,
      async (newModel) => {
        if (newModel) {
          console.log(newModel);
          const { data } = await ApiService.get(
            `car-brand-series?model=${newModel}`
          );
          subModels.value = data.datas;

          const selectedModel = models.value.find(
            (item) => item.id == newModel
          );
          formData.value.type = selectedModel.type.id;
          formData.value.subModel = null;
        }
      }
    );

    return {
      brands,
      models,
      subModels,
      types,
      tags,
      formData,
      submit,
      loading,
      limitMaxNumber,
      modalRef,
    };
  },
};
</script>

<style scoped lang="sass">
input, select, .form-select
  font-size: 1.5rem
  font-weight: 600

li:hover
  background-color:  #3585fd !important
  color: #fff !important

body.dark-mode .form-check-input:not(:checked)
  background-color: #fff !important

.el-select
  width: 100%
</style>
