<template>
  <div
    class="modal fade"
    id="brand-modal"
    tabindex="-1"
    aria-labelledby="confirm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header py-4 fs-3">Edit Brand</div>
        <div class="modal-body px-12">
          <div class="d-flex mb-2">
            <el-input v-model="inputRef" type="text" placeholder="Add Brand" />
            <button
              @click="addItem()"
              class="ms-5 btn btn-primary"
              :disabled="!inputRef || loading"
              :data-kt-indicator="loading ? 'on' : 'off'"
            >
              Add
            </button>
          </div>
          <div
            :key="i"
            v-for="(item, i) in items"
            class="d-flex py-3 border-bottom justify-content-between align-items-center"
          >
            <div v-if="currentIndex !== i">
              {{ item.name }}
            </div>
            <div v-else>
              <el-input
                @blur="editItem(item)"
                v-model="item.name"
                class="edit"
                type="text"
              />
            </div>
            <div v-if="currentIndex !== i" class="d-flex align-items-center">
              <span
                @click="currentIndex = i"
                class="material-icons-outlined fs-4 text-success me-2"
              >
                edit
              </span>
              <span
                @click="currentId = item.id"
                data-bs-toggle="modal"
                data-bs-target="#confirm-brand"
                class="material-icons-outlined fs-4 text-danger"
              >
                delete
              </span>
            </div>
          </div>
          <div
            v-if="!items.length || loading"
            class="overlay-layer card-rounded bg-dark bg-opacity-5 text-center mt-6"
          >
            <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmModal
    :canBack="true"
    idProps="confirm-brand"
    backTo="brand-modal"
    message="Are you sure you want to delete this item? This action is
            irreversible."
  >
    <template v-slot:cancel>
      <button
        type="button"
        name="cancel"
        class="btn btn-danger px-10"
        data-bs-toggle="modal"
        data-bs-target="#brand-modal"
        aria-label="Close"
      >
        Cancel
      </button>
    </template>
    <button
      @click="deleteItem()"
      data-bs-toggle="modal"
      data-bs-target="#brand-modal"
      type="button"
      name="confirm"
      class="btn btn-primary px-10"
    >
      Delete
    </button>
  </ConfirmModal>
</template>

<script>
import { ref, computed } from 'vue';
import ApiService from '@/core/services/ApiService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

export default {
  name: 'AddBrandModal',
  props: {
    modelValue: Object,
  },
  components: {
    ConfirmModal,
  },
  setup(props, { emit }) {
    const inputRef = ref('');
    const items = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const loading = ref(false);
    const currentId = ref(0);
    const currentIndex = ref(-1);

    const addItem = async (page = 1, pageSize = 1000000) => {
      if (!inputRef.value) return;

      loading.value = true;
      await ApiService.post('car-brand', {
        name: inputRef.value,
      });

      const { data } = await ApiService.get(
        `car-brand?page=${page}&pageSize=${pageSize}`
      );

      items.value = data.datas;
      inputRef.value = '';
      loading.value = false;
    };

    const deleteItem = async () => {
      loading.value = true;
      await ApiService.delete(`car-brand/${currentId.value}`);
      items.value = items.value.filter((item) => item.id !== currentId.value);
      loading.value = false;
    };

    const editItem = async (item) => {
      loading.value = true;
      await ApiService.patch(`car-brand/${item.id}`, {
        name: item.name,
      });
      currentIndex.value = -1;
      loading.value = false;
    };

    return {
      inputRef,
      items,
      addItem,
      loading,
      deleteItem,
      currentId,
      currentIndex,
      editItem,
    };
  },
};
</script>

<style lang="sass" scoped>
span
  cursor: pointer

.edit
  max-height: 30px
  font-size: 1rem
</style>
