<template>
  <div
    class="modal fade"
    id="sub-model-modal"
    tabindex="-1"
    aria-labelledby="confirm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header py-4 fs-3">Edit Sub Model</div>
        <div class="modal-body px-12">
          <div class="d-flex mb-2 justify-content-end">
            <button
              class="ms-5 btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#create-sub-model-modal"
              :disable="loading"
              :data-kt-indicator="loading ? 'on' : 'off'"
            >
              Add
            </button>
          </div>

          <div
            :key="i"
            v-for="(item, i) in subModels"
            class="d-flex py-3 border-bottom justify-content-between align-items-center"
          >
            <div v-if="currentIndex !== i">
              {{ item.name }}
            </div>
            <div class="d-flex" v-else>
              <el-input v-model="item.name" class="edit me-3" type="text" />
              <el-select class="edit" v-model="item.model" filterable>
                <el-option
                  :key="j"
                  v-for="(model, j) in models"
                  :value="model.id"
                  :label="model.name"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="currentIndex !== i" class="d-flex align-items-center">
              <span
                @click="currentIndex = i"
                class="material-icons-outlined fs-4 text-success me-2"
              >
                edit
              </span>
              <span
                @click="currentId = item.id"
                data-bs-toggle="modal"
                data-bs-target="#confirm-sub-model"
                class="material-icons-outlined fs-4 text-danger"
              >
                delete
              </span>
            </div>
            <div
              v-if="currentIndex === i"
              class="d-flex align-items-center ms-3"
            >
              <span
                @click="editItem(item)"
                class="material-icons-outlined fs-4 text-success"
              >
                save
              </span>
            </div>
          </div>
          <div v-if="!Object.values(models).length">
            <span>No Data</span>
          </div>
          <div
            v-else-if="loading"
            class="overlay-layer card-rounded bg-dark bg-opacity-5 text-center mt-6"
          >
            <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmModal
    :canBack="true"
    idProps="confirm-sub-model"
    backTo="sub-model-modal"
    message="Are you sure you want to delete this item? This action is
            irreversible."
  >
    <template v-slot:cancel>
      <button
        type="button"
        name="cancel"
        class="btn btn-danger px-10"
        data-bs-toggle="modal"
        data-bs-target="#sub-model-modal"
        aria-label="Close"
      >
        Cancel
      </button>
    </template>
    <button
      @click="deleteItem()"
      data-bs-toggle="modal"
      data-bs-target="#sub-model-modal"
      type="button"
      name="confirm"
      class="btn btn-primary px-10"
    >
      Delete
    </button>
  </ConfirmModal>

  <div
    class="modal fade"
    id="create-sub-model-modal"
    tabindex="-1"
    aria-labelledby="confirm"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header py-4 fs-3">Add Sub Model</div>
        <div class="modal-body px-12">
          <form>
            <el-input
              v-model="inputRef.name"
              class="mb-3"
              type="text"
              placeholder="Add Sub Model"
            />

            <el-select
              v-model="inputRef.model"
              class="mb-3 w-100"
              placeholder="Select Model"
              filterable
              :disabled="true"
            >
              <el-option
                :key="i"
                v-for="(model, i) in models"
                :value="model.id"
                :label="model.name"
              >
              </el-option>
            </el-select>
          </form>

          <div class="d-flex justify-content-between pt-4">
            <button
              type="button"
              class="btn btn-danger flex-fill me-3"
              name="cancel"
              data-bs-toggle="modal"
              data-bs-target="#sub-model-modal"
              aria-label="Back"
            >
              Back
            </button>
            <button
              @click="addItem()"
              class="btn btn-primary flex-fill ms-3"
              data-bs-toggle="modal"
              data-bs-target="#sub-model-modal "
              type="button"
              name="Add"
              :disabled="Object.values(inputRef).includes('')"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch } from 'vue';
import ApiService from '@/core/services/ApiService';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

export default {
  name: 'AddSubModelModal',
  props: {
    subModels: Array,
    models: Array,
    selectedModel: String,
  },
  components: {
    ConfirmModal,
  },
  setup(props) {
    const inputRef = ref({ name: '', model: '' });

    const items = reactive({
      subModels: [],
      models: [],
      selectedModel: null,
    });
    const loading = ref(false);
    const currentId = ref(0);
    const currentIndex = ref(-1);

    const addItem = async () => {
      if (Object.values(inputRef.value).includes('')) return;

      loading.value = true;
      const { data } = await ApiService.post(
        'car-brand-series',
        inputRef.value
      );

      items.subModels.push(data.data);
      inputRef.value.name = '';
      loading.value = false;
    };

    const deleteItem = async () => {
      loading.value = true;
      await ApiService.delete(`car-brand-series/${currentId.value}`);
      items.subModels.splice(
        items.subModels.findIndex((item) => item.id == currentId.value),
        1
      );
      loading.value = false;
    };

    const editItem = async (item) => {
      loading.value = true;
      await ApiService.patch(`car-brand-series/${item.id}`, {
        name: item.name,
      });
      currentIndex.value = -1;
      loading.value = false;
    };

    watch(
      () => props.subModels,
      (subModels) => {
        items.subModels = subModels;
      }
    );

    watch(
      () => props.models,
      (models) => {
        items.models = models;
      }
    );

    watch(
      () => props.selectedModel,
      (selectedModel) => {
        inputRef.value.model = selectedModel;
      }
    );

    return {
      ...toRefs(items),
      inputRef,
      addItem,
      loading,
      deleteItem,
      currentId,
      currentIndex,
      editItem,
    };
  },
};
</script>

<style lang="sass" scoped>
span
  cursor: pointer

.edit
  max-height: 30px
  font-size: 1rem
</style>
